import { initializeApollo } from 'lib/apolloClient';
import BlockMix from '@/components/BlockMix/BlockMix';

import { getGlobalData } from 'lib/global.js';
import { getEntityBlocks } from 'gql/common/blocks';
import { GetStaticPropsContext } from 'next';
import { Page, Post } from 'generated-graphql';
import { CHILD_PAGES } from 'gql/pages';

import styles from '@/styles/Solution.module.scss';
import appConfig from '@/app.config';

export default function Solution({ pageBlocks }: { pageBlocks: Post }) {
  return (
    <div className={styles.page}>
      <BlockMix
        blocksData={pageBlocks?.editorBlocks}
        acfData={pageBlocks?.builder?.sections}
      />
    </div>
  );
}

export async function getStaticPaths() {
  const apolloClient = initializeApollo();
  const allSolutionsPages = await apolloClient.query({
    query: CHILD_PAGES,
    variables: { id: 'solutions' },
  });

  const data = allSolutionsPages?.data?.page.children.nodes || [];
  return {
    paths: data.map((node: Page) => `/solutions/${node.slug}`) || [],
    fallback: false,
  };
}

export async function getStaticProps(context: GetStaticPropsContext) {
  const { params } = context;
  const apolloClient = initializeApollo();
  const pageBlocks = await apolloClient.query({
    query: getEntityBlocks('page'),
    variables: {
      id: `/solutions/${params?.slug}`,
      idType: 'URI',
      asPreview: false,
    },
  });
  const pageProps = {
    pageBlocks: pageBlocks?.data?.page,
  };

  return await getGlobalData(pageProps, apolloClient, {
    revalidate: appConfig.revalidate,
  });
}
